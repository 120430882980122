import React, { Component } from 'react';

class FeatureLeft extends Component {
    constructor(props){
        super(props);
        this.state = {
            collLeft: [
                {
                    id: 1,
                    icon: 'fa fa-handshake-o',
                    title: 'Liderazgo en el Empresariado',
                    text: 'Con más de 32 años en el sector empresarial, Lorena ha destacado por su impulso en la comercialización de artesanías y alimentos, enfocándose en la colaboración y el empoderamiento de micro y pequeñas empresas.'
                },
                {
                    id: 2,
                    icon: 'fa fa-globe',
                    title: 'Expansión y Promoción del Arte Hidalguense',
                    text: 'Activa en la promoción del arte local, Lorena ha jugado un papel crucial en llevar los productos artesanales de Hidalgo a mercados nacionales e internacionales, fomentando el reconocimiento y valoración del trabajo artesanal.'
                },
                {
                    id: 3,
                    icon: 'fa fa-building',
                    title: 'Incursión en el Sector Inmobiliario',
                    text: 'Recientemente, Lorena ha expandido su campo de acción al sector inmobiliario, demostrando su adaptabilidad y deseo de enfrentar nuevos desafíos y oportunidades de mercado.'
                }
            ]
        }
    }

    render() {
        return (
            <div className="topic-items">
            {
                    this.state.collLeft.map((data, i) => (
                        <div className="topic-item" key={i}>
                            <div className="icon-box">
                                <i className={data.icon}></i>
                            </div>
                            <div className="topic-content">
                                <h4>{data.title}</h4>
                                <p>{data.text}</p>
                            </div>
                        </div>
                    
                    ))
            }
            </div>
        );
    }

}

export default FeatureLeft;
