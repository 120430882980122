import React, { Component } from 'react';

class Feature2 extends Component {
    constructor(props){
        super(props);
        this.state = {
           features: [
                {
                    id: 1,
                    icon: 'bi bi-badge2',
                    title: 'Empoderamiento Empresarial',
                    text: 'Comprometida con el empoderamiento económico y social, ha sido un pilar en el crecimiento de las pequeñas empresas, enfocadas en mujeres emprendedoras.'
                },
                {
                    id: 2,
                    icon: 'bi bi-badge2',
                    title: 'Liderazgo en Artesanía',
                    text: 'Promotora activa del arte hidalguense, Lorena ha ayudado a artesanos locales a posicionarse en mercados nacionales e internacionales, subrayando la importancia de la cultura y tradición regional.'
                },
                {
                    id: 3,
                    icon: 'bi bi-badge2',
                    title: 'Innovación Inmobiliaria',
                    text: 'Innovando en el sector inmobiliario, ha introducido nuevas estrategias para el desarrollo de proyectos que beneficien a su comunidad, demostrando su versatilidad y visión de futuro.'
                }
                
            ]
        }
    }

    render() {
        return (
            <section className="feature-2 section-padding ">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="section-heading center-heading text-center mb-60">
                                <h3 className="heading-title">Habilidades y Valores</h3>
                                <p>Construyendo Comunidad con Integridad y Liderazgo</p>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        {
                            this.state.features.map((data,index) => (
                                <div className="col-lg-4 col-md-6">
                                    <div className="feature-style-2 mb-4 mb-lg-0" key={data.id}>
                                        <i className={data.icon}></i>
                                        <div className="feature-text">
                                            <h4>{data.title}</h4>
                                            <p>{data.text}</p>
                                        </div>
                                    </div>
                                </div>
                            
                            ))
                        }
                        
                    </div>
                </div>
            </section> 
        );
    }
}

export default Feature2;
