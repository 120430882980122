import React, { Component } from 'react';

class ContainerLeft extends Component {
    constructor(props){
        super(props);
        this.state = {
            collLeft: [
                {
                    id: 1,
                    title: 'Comercio y Comercialización',
                    text: 'Más de tres décadas liderando en el sector de alimentos y artesanías, impulsando el reconocimiento y la colocación de productos artesanales en mercados nacionales e internacionales.'
                },
                {
                    id: 2,
                    title: 'Presidencia de AMMJE',
                    text: 'Gestión como presidenta de la Asociación Mexicana de Mujeres Jefas de Empresa, donde fortaleció la colaboración y crecimiento empresarial entre mujeres emprendedoras a nivel nacional.'
                },
                {
                    id: 3,
                    title: 'Desarrollo Empresarial y Social',
                    text: 'Compromiso constante con el empoderamiento económico y social, destacando por su labor en la creación de redes de apoyo y mentoría para mujeres empresarias en el Estado de Hidalgo y más allá.'
                }
            ]
        }
    }
    render() {
        return (
            <div className="chapter-list border-right-0">
                 {
                    this.state.collLeft.map((data, i) => (
                        <div className="chapter-item" key={i}>
                            <h4>{data.title}</h4>
                            <p>{data.text}</p>
                        </div>
                    ))
                }
            </div>
        );
    }
}

export default ContainerLeft;
