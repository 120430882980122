import React, { Component } from 'react';

class Footer extends Component {
    render() {
      // Estilos en línea para los íconos
      const iconStyle = {
        marginRight: '15px', // Espacio entre íconos
        display: 'inline'    // Asegura que los íconos se muestren en línea
      };
  
      return (          
            <section className="footer pt-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 me-auto col-sm-6 col-md-12">
                            <div className="widget footer-widget mb-5 mb-lg-0">
                                <h5 className="widget-title">Lorena García Cázares</h5>
                                <p className="mt-3">Explora más sobre el liderazgo y las iniciativas de Lorena, un punto de encuentro para quienes buscan promover el empoderamiento y desarrollo empresarial.</p>
                            </div>
                        </div>
                        
                        <div className="col-lg-6 col-sm-6 col-md-12">
                          <div className="row">
                            <div className="col-lg-10 col-md-10">
                              <div className="footer-widget mb-5 mb-lg-0">
                                <h5 className="widget-title">Conecta con Lorena</h5>
                                <p>Si tienes ideas, preguntas o quieres sumarte a iniciativas de empoderamiento y desarrollo empresarial, no dudes en conectarte.</p>
                                <div>
                                  <a href="https://www.facebook.com/Lorenagarciacaz/" style={iconStyle}><i className="fab fa-facebook-f"></i></a>
                                  <a href="https://www.instagram.com/lorenagarciacaz?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" style={iconStyle}><i className="fab fa-instagram"></i></a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>

                <div className="footer-btm">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="copyright">
                                    <p>© 2024 Lorena García Cázares - Todos los derechos reservados</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="copyright">
                                    <a href="/PrivacyPolicy">Política de Privacidad</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                <div className="fixed-btm-top">
                    <a href="https://www.facebook.com/Lorenagarciacaz/" className="js-scroll-trigger scroll-to-top"><i className="fa fa-angle-up"></i></a>
                </div>
            </section>
        );
    }
}

export default Footer;
