import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Author extends Component {
    constructor(props){
        super(props);
        this.state = {
            listItem: [
                {
                    id: 1,
                    icon: 'bi bi-award',
                    title: 'Liderazgo Empresarial',
                },
                {
                    id: 2,
                    icon: 'bi bi-hand-thumbs-up',
                    title: 'Promoción del Arte Hidalguense',
                },
                {
                    id: 3,
                    icon: 'bi bi-book-half',
                    title: 'Educación Continua y Desarrollo',
                },
            ]
        }
    }
    render() {
        return (
            <section className="author-section section-padding bg-white" id="author">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="section-heading center-heading text-center mb-60">
                                <h3 className="heading-title">Dedicación y Excelencia en la Promoción Empresarial</h3>
                                <p>Liderazgo con Compromiso y Visión Empresarial</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-5 col-md-6">
                            <div className="auhtor-img mb-4 mb-lg-0">
                                 <img src="assets/images/lorenatres.jpg" alt="Lorena García Cázares" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 ps-4">
                            <div className="section-heading mb-0">
                                <h3 className="heading-title">Lorena García Cázares</h3>
                                <p className="lead">Soy Lorena García Cázares, con una trayectoria de más de tres décadas en el sector empresarial y artesanal. Mi enfoque está en el empoderamiento y desarrollo económico a través del arte y la cultura de Hidalgo.</p>
                                <p>Dedicada a la comercialización de productos artesanales y alimentos, he liderado iniciativas que refuerzan la tradición y la innovación, apoyando especialmente el desarrollo de las mujeres emprendedoras.</p>
                            </div>

                            <div className="author-desc">
                                <ul className="list-unstyled">
                                    {
                                    this.state.listItem.map((data, i) => (
                                        <li key={data.id}><i className={data.icon}></i> {data.title}</li>
                                        ))
                                    }
                                </ul>
                                <p>Sígueme en mis redes sociales para estar al tanto de mis proyectos y actividades:</p>
                                <ul>
                                    <li><Link to="https://www.facebook.com/Lorenagarciacaz/">Facebook</Link></li>
                                    <li><Link to="https://www.instagram.com/lorenagarciacaz?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">Instagram</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
        );
    }
}

export default Author;
