import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Intro extends Component {
    render() {
        return (
            <section className="pt-100 center-about bg-grey">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="section-heading text-center">
                                <span>Transformando el ámbito empresarial y social con un compromiso fiel a los valores y tradiciones.</span>
                                <h2>Unámonos para seguir el camino de Lorena García Cázares en la administración pública, donde su liderazgo y voluntad de servir impactan positivamente, promoviendo la unidad, seguridad y el progreso continuo.</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Intro;
