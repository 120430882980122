import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class About extends Component {
    constructor(props){
        super(props);
        this.state = {
            about: [
                {
                    id: 1,
                    icon: 'bi bi-badge2',
                    title: 'Perfil Profesional',
                    text: 'Lorena García Cázares ha dedicado más de tres décadas a liderar y desarrollar empresas en el sector de alimentos y artesanías, destacando en el comercio y la comercialización con un enfoque en el empoderamiento económico y social.'
                },
                {
                    id: 2,
                    icon: 'bi bi-support',
                    title: 'Trayectoria Destacada',
                    text: 'Ha ocupado cargos significativos como Presidenta de la Asociación Mexicana de Mujeres Jefas de Empresa (AMMJE) y ha sido influencial en el Consejo Coordinador Empresarial del Estado de Hidalgo, fomentando la colaboración y el crecimiento empresarial.'
                },
                {
                    id: 3,
                    icon: 'bi bi-rocket2',
                    title: 'Compromiso con la Excelencia',
                    text: 'Su enfoque en la innovación y el liderazgo ha sido fundamental en sus roles, destacando su participación activa en la mejora de las condiciones empresariales para las micro y pequeñas empresas, así como en el desarrollo profesional de mujeres emprendedoras.'
                }
            ]
        }
    }
    render() {
        return (
            <section className="about-section section-padding bg-grey" id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="about-img">
                                <img src="assets/images/lorenados.jpg" alt="Lorena García Cázares" className="img-fluid"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="section-heading">
                                <span className="subheading">Acerca de Lorena</span>
                                <h3 className="heading-title">Impacto y Liderazgo en el Empresariado</h3>
                                <p>Lorena García Cázares es reconocida por su habilidad para liderar y transformar el sector empresarial, especialmente en apoyo a las mujeres emprendedoras y el fortalecimiento de las pequeñas empresas.</p>
                            </div>
                            {
                                this.state.about.map((data, i) => (
                                    <div className="about-text-block" key={i}>
                                        <i className={data.icon}></i>
                                        <h4>{data.title}</h4>
                                        <p>{data.text}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </section>  
        );
    }
}

export default About;
